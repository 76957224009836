import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Input, Row, Spinner, CardHeader } from 'reactstrap';
import { RiMessage2Fill } from 'react-icons/ri';
import { AiOutlineSend } from 'react-icons/ai';
import { FaChevronDown } from "react-icons/fa";
import moment from 'moment-timezone';
import firestore from '../firebaseConfig';
import firebase from "firebase/compat/app";
import { useCookies } from 'react-cookie';
import withBadge from '../hocs/withBadge';
import axios from 'axios';

const   ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  
  const cookies = useCookies(['currentUser', 'userDetails', 'isLoggedIn', 'userRole', 'judge_id', 'awardStatus', 'token','award_id','section','token']);
  const currentUser = cookies[0].currentUser;
  const award_id = cookies[0].award_id;
  const userDetails = cookies[0].userDetails;
  const token = cookies[0].token;
  
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [entrantChatName, setEntrantChatName] = useState('');
  const [formStatus, setFormStatus] = useState('standby');
  const [awardData, setAwardData] = useState([]);

  const userToken = {
    user_id: currentUser,
    token: token
  }

  const getAward = () => axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id, {
    params: userToken
  });
  
  const handleChangeComment = async (e) => {
    const { value } = e.target;
    
    setNewComment(value);
  };
  
  const formatTimestamp = (timestamp) => {
    moment.tz.setDefault('Europe/Dublin');
    const now = moment();
    let format = 'MMM DD, YYYY h:mm a';
    
    if (now.format('YYYY') === moment(timestamp).format('YYYY'))
      format = 'MMM DD h:mm a';
    
    return moment(timestamp).format(format);
  };
  
  const handleKeyDown = async (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault()
      await handleSendMessage(e);
    } else if (e.keyCode == 27) {
      setIsOpen(false);
    }
  }
  
  const handleSendMessage = async (e) => {
    e.preventDefault();
    setFormStatus('loading');
    setNewComment('');
  
    try {
      moment.tz.setDefault('Europe/Dublin');
      const now = moment().valueOf();
  
      let method = 'update';
      let data;
  
      if (!entrantChatName) {
        method = 'set';
        data = {
          name: userDetails.user.name,
          user_id: userDetails.id,
          entrant_status: 'Viewed'
        };
      }
  
      // 1. Update entrant doc (set or update)
      await firestore
        .collection("awards")
        .doc(award_id)
        .collection("entrants")
        .doc(currentUser)
        [method]({
          ...data,
          admin_status: 'You have unread message(s)',
          last_chat_time: now
        });
  
      // 2. Add chat message
      await firestore
        .collection("awards")
        .doc(award_id)
        .collection("entrants")
        .doc(currentUser)
        .collection("chats")
        .add({
          award: awardData.name,
          user_id: userDetails.id,
          first_name: userDetails.user.first_name,
          last_name: userDetails.user.last_name,
          email: userDetails.user.email,
          image_url: userDetails.user.image_url,
          message: newComment,
          role: 'Entrant',
          timestamp_label: now,
          timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
        });
  
      // 3. 🔥 Update award document with award name
      if (awardData.name) {
        await firestore
          .collection("awards")
          .doc(award_id)
          .set(
            { name: awardData.name },
            { merge: true }
          );
      }
  
      setFormStatus('standby');
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };  
  
  const toggleChat = async () => {
    const isChatOpen = isOpen;
    setIsOpen(prev => !prev);
    if (!isChatOpen) {
      await firestore
        .collection("awards")
        .doc(award_id)
        .collection("entrants")
        .doc(currentUser)
        .update({
          entrant_status: 'Viewed'
        });
    }
  }
  
  const viewMessage = async () => {
    await firestore
      .collection("awards")
      .doc(award_id)
      .collection("entrants")
      .doc(currentUser)
      .update({
        entrant_status: 'Viewed'
      });
  }
  
  useEffect(() => {
    const unsubscribe = firestore
      .collection("awards")
      .doc(award_id)
      .collection("entrants")
      .doc(currentUser)
      .collection("chats")
      .orderBy("timestamp", "desc")
      .onSnapshot(async (snapshot) => {
        const chatData = snapshot.docs.map((doc) => doc.data());
        setComments(chatData);
      });
    return () => unsubscribe();
  }, [currentUser]);
  
  useEffect(() => {
    const unsubscribe = firestore
      .collection("awards")
      .doc(award_id)
      .collection("entrants")
      .doc(currentUser)
      .onSnapshot((snapshot) => {
        const chatData = snapshot.data();
        if (chatData) {
          setEntrantChatName(chatData.name);
          setNewMessage(chatData.entrant_status !== 'Viewed');
        }
      });
    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    getAward().then(response => {
      const { data } = response.data;
      if (data) {
        setAwardData(data);
      } else {
        console.error("Error getting award!");
      }
    }).catch((error) => {
      console.error("Error getting award:", error);
    });
  }, [award_id])
  
  return (
    <>
      <div className={`entrant-chat-widget-container ${isOpen ? 'chat-container-show ' : ''}`}>
        <Card className="h-100 entrant-chat-widget-container-card">
          <CardHeader>
            <h3 className="header-text mb-0" style={{ color: "#1ec2f3" }}>CHAT WITH US</h3>
          </CardHeader>
          <CardBody className='chat-container comment-auto-scroll p-4'>
            {comments.map(({ id, user_id, first_name, last_name, role, message, timestamp_label, image_url }, index) => (
              <div className='entry-comment-container' key={index}>
                <>
        
                  <Card className='comment-body' style={{ border: 'none' }}>
                    <CardBody className='px-1'>
                      {role === 'Entrant' ?
                        <Row className='align-items-end'>
                          <Col lg='1' className='mb-0 px-0'>
                            {/*{image_url ?*/}
                            {/*  <div className='profile-picture-container cursor-pointer' style={{*/}
                            {/*    backgroundImage: 'url(' + process.env.REACT_APP_URL + 'storage/user/' + image_url + ')',*/}
                            {/*    width: '35px',*/}
                            {/*    height: '35px',*/}
                            {/*  }}></div>*/}
                            {/*  :*/}
                            {/*  <img src={PlaceholderIcon} className='user-icon-image' />*/}
                            {/*}*/}
                          </Col>
                          <Col lg='11' className='mb-0 px-0'>
                            <Card className='comment-send'>
                              <CardBody className='px-3 py-2'>
                                <p className='comment-data mb-0'>{message}</p>
                              </CardBody>
                              <p className='comment-send-name'>You <span>{formatTimestamp(timestamp_label)}</span></p>
                            </Card>
                          </Col>
                        </Row>
                        :
                        <Row className='align-items-end'>
                          <Col lg='11' className='mb-0 px-0'>
                            <Card className='comment-receive'>
                              <CardBody className='px-3 py-2'>
                                <p className='comment-data mb-0'>{message}</p>
                              </CardBody>
                              <p className='comment-receive-name'>Admin <span>{formatTimestamp(timestamp_label)}</span></p>
                            </Card>
                          </Col>
                          <Col lg='1' className='mb-0 px-0'>
                            {/*<img src={PlaceholderIcon} className='user-icon-image' />*/}
                          </Col>
                        </Row>
                      }
                    </CardBody>
                  </Card>
      
                </>
              </div>))}
          </CardBody>
          <CardFooter>
  
            <Row className='align-items-center'>
              <Col lg='10' className='mb-0'>
                <Input
                  type='textarea'
                  rows='1'
                  placeholder='Message'
                  className='comment-area'
                  onChange={handleChangeComment}
                  onKeyDown={handleKeyDown}
                  value={newComment}
                  maxlength={255}
                  disabled={formStatus === 'loading'}
                  onFocus={viewMessage}
                />
              </Col>
              <Col lg='2' className='pl-0 mb-0'>
                {formStatus === 'loading' ?
                  <button
                    className='send-comment-button mr-1'
                  >
                    <Spinner
                      color='info'
                      size='sm' />
                  </button>
                  :
                  <button
                    className='send-comment-button mr-1'
                    onClick={handleSendMessage}
                  >
                    <AiOutlineSend />
                  </button>
                }
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
      <div className='entrant-chat-widget cursor-pointer' onClick={toggleChat}>
        <span className={`position-relative badge badge-danger chat-widget-notif ${newMessage? 'new-message' : ''}`}>
          <small>&nbsp;</small>
        </span>
        {isOpen ?
          <FaChevronDown className={`entrant-chat-widget-icon ${newMessage? 'new-message' : ''}`} />
          :
          <RiMessage2Fill className={`entrant-chat-widget-icon ${newMessage? 'new-message' : ''}`} />
        }
      </div>
    </>
  );
};

export default ChatWidget;