import React, { useEffect, useState } from 'react';
import { Media } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { BsChatDots } from 'react-icons/bs';

const ChatNotifications = ({loading, unreadAwards}) => {
  const history = useHistory();

  if (loading) {
    return <p className="text-center mb-0">Loading...</p>;
  }

  if (unreadAwards.length === 0) {
    return (
      <Media className="font-style n notification-single">
        <Media body middle className="align-self-center text-center">
          No unread chats
        </Media>
      </Media>
    );
  }

  return (
    <>
      {unreadAwards.map(({ awardId, awardName, unreadCount }, index) => (
        <>
        <Media
          key={awardId}
          className="font-style n notification-single clickable"
          onClick={() => history.push(`/admin/award/chats?award_id=${awardId}`)}
        >
          <Media left className="align-self-center pr-3">
            <BsChatDots size={24} color="#1EC2F3" />
          </Media>
          <Media body middle className="align-self-center">
            <div className="header-notification">
              <strong>{awardName}</strong><br />
              {unreadCount} unread message{unreadCount > 1 ? 's' : ''}
            </div>
          </Media>
        </Media>
        {unreadAwards.length != index + 1 ?
            <hr />
            :
            null
        }
        </>
      ))}
    </>
  );
};

export default ChatNotifications;
